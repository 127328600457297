import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Articles from "../../components/wijzewoordenweb/articles";
import Header from "../../components/wijzewoordenweb/header";

export default function WijzeWoordenWeb() {
  return (
    <>
      <Navbar />
      <Header />
      <Articles />
      <Footer mobileMenu={false} />
    </>
  )
}