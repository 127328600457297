import { lazy } from 'react';

import Home from './pages/home';
import WijzeWoordenWeb from './pages/wijzewoordenweb';

const Error = lazy(() => import('./pages/error'));

export const publicRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/wijzewoordenweb',
    element: <WijzeWoordenWeb />,
  },
  {
    path: "*",
    element: <Error errorCode={404} errorMessage={<>Deze pagina is zoekende, net zoals <span>jij</span> op maandagochtend in de collegezaal.</>} />,
  }
]