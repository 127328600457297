import styles from "./style.module.scss";
import FirstSlider from "./FirstSlider";
import SecondSlider from "./SecondSlider";
import { Link } from "react-router-dom";

export default function Quote() {
  const scrollTop = () =>{   window.scrollTo({top: 0, behavior: 'smooth'});};
  return (
    <div className={styles.quoteSection}>
      <FirstSlider />
      <div className={styles.content}>
        <div className={styles.left}>
        </div>
        <div className={styles.right}>
          <p className={styles.quoteText}>
            <span>“</span>Alles kost <span>Geld</span> Maar Je Hebt{" "}
            <span>Niet</span> Alles Nodig”
          </p>
          <Link onClick={scrollTop} to="/wijzewoordenweb">
            <div className={styles.tipsButton}>
              <div className={styles.tips}>
                <p>WijzeWoordenWeb</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <ellipse
                  cx="16.0389"
                  cy="15.9436"
                  rx="15.9686"
                  ry="15.9436"
                  fill="#E1FA00"
                />
                <path
                  d="M23.2842 9.74318C23.2842 9.29418 22.9196 8.9302 22.4699 8.9302L15.1416 8.9302C14.6919 8.9302 14.3273 9.29418 14.3273 9.74318C14.3273 10.1922 14.6919 10.5562 15.1416 10.5562H21.6556V17.06C21.6556 17.509 22.0202 17.873 22.4699 17.873C22.9196 17.873 23.2842 17.509 23.2842 17.06L23.2842 9.74318ZM10.4039 22.9401L23.0457 10.318L21.8941 9.16832L9.25236 21.7904L10.4039 22.9401Z"
                  fill="#213305"
                />
              </svg>
            </div>
          </Link>
        </div>
      </div>
      <SecondSlider />
    </div>
  );
}
