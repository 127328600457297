import styles from "./style.module.scss";

export default function Header() {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.textRow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="113"
              viewBox="0 0 87 113"
              fill="none"
            >
              <path
                d="M86.2357 95.9269V112.024H0L0 97.4052L62.911 16.2616H1.80684V0L84.4288 0V14.7833L22.1749 95.9269H86.2357Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="79"
              height="113"
              viewBox="0 0 79 113"
              fill="none"
            >
              <path
                d="M18.7048 16.2616V47.1422H72.91V63.4037H18.7048V95.9269H78.8233V112.024H0.964844V0L78.8233 0V16.2616H18.7048Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="97"
              height="113"
              viewBox="0 0 97 113"
              fill="none"
            >
              <path
                d="M0.716797 112.024V0L18.4567 0V112.024H0.716797ZM96.1509 112.024H74.1403L21.2491 53.384L70.6909 0L93.1943 0L44.081 53.2197L96.1509 112.024Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="78"
              height="113"
              viewBox="0 0 78 113"
              fill="none"
            >
              <path
                d="M17.8844 16.2616V47.1422H72.0897V63.4037H17.8844V95.9269H78.003V112.024H0.144531V0L78.003 0V16.2616H17.8844Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="89"
              height="113"
              viewBox="0 0 89 113"
              fill="none"
            >
              <path
                d="M88.9429 112.024H69.3961L41.308 67.8387H18.6403V112.024H0.900391V0L41.308 0C47.3308 0 52.9156 0.547522 58.0623 1.64258C63.2091 2.62813 67.644 4.43498 71.3672 7.06312C75.1999 9.58175 78.1566 13.0312 80.2372 17.4114C82.4273 21.6821 83.5223 27.1574 83.5223 33.8372C83.5223 38.4365 82.9201 42.5429 81.7155 46.1566C80.6205 49.6608 79.0326 52.7269 76.952 55.3551C74.8714 57.8737 72.3528 59.9543 69.3961 61.5969C66.549 63.2395 63.3733 64.5535 59.8691 65.5391L88.9429 112.024ZM34.2449 51.5771C38.2966 51.5771 42.184 51.5224 45.9072 51.4129C49.6304 51.3034 52.9156 50.7011 55.7627 49.606C58.6098 48.511 60.8547 46.7589 62.4973 44.3498C64.2494 41.9407 65.1254 38.4365 65.1254 33.8372C65.1254 29.895 64.5232 26.7741 63.3186 24.4745C62.114 22.1749 60.4714 20.4228 58.3908 19.2182C56.3102 18.0137 53.8464 17.2471 50.9992 16.9186C48.2616 16.4806 45.3049 16.2616 42.1292 16.2616H18.6403V51.5771H34.2449Z"
                fill="#E1FA00"
              />
            </svg>
          </div>
          <div className={styles.textRow}>
            <svg
              width="112"
              height="114"
              viewBox="0 0 112 114"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M97.5599 0.504395C105.065 0.504395 111.068 5.75762 111.068 15.0133C111.068 37.7773 83.3012 65.044 43.7769 113.574H41.7757L28.2674 38.5277C24.7652 19.0158 19.2618 12.5118 10.7566 12.5118C8.00492 12.5118 4.75291 13.5124 0.750453 14.7631L0 10.7607L29.268 0.504395L33.0203 0.75455C43.0264 6.75823 46.2784 21.017 49.2803 39.028L57.2852 90.0594C82.8008 58.7902 96.5593 42.0299 96.5593 30.5228C96.5593 20.2665 86.5532 19.0158 86.5532 9.76008C86.5532 4.00655 91.8064 0.504395 97.5599 0.504395Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              width="111"
              height="113"
              viewBox="0 0 111 113"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M110.579 112.917H92.0175L80.6837 84.9929H30.4206L18.9225 112.917H0.361328L46.6822 0.892578H64.4221L110.579 112.917ZM74.1134 68.7313L55.5521 23.0675L36.9909 68.7313H74.1134Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              width="93"
              height="113"
              viewBox="0 0 93 113"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M92.2107 0.892578V112.917H76.2776L18.4586 30.9519V112.917H0.71875V0.892578H17.1446L74.4707 83.6788V0.892578H92.2107Z"
                fill="#E1FA00"
              />
            </svg>
          </div>
          <div className={styles.textRow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="162"
              height="115"
              viewBox="0 0 162 115"
              fill="none"
            >
              <path
                d="M100.943 16.9091V47.7896H155.148V64.0512H100.943V96.5744H161.062V112.672H83.2031V0.647461H161.062V16.9091H100.943Z"
                fill="#E1FA00"
              />
              <path
                d="M23.8175 0.647461H68.3315V76.2063C68.3315 80.9151 67.6745 85.569 66.3604 90.1683C65.0464 94.658 62.8562 98.7097 59.79 102.323C56.7239 105.828 52.7269 108.675 47.7992 110.865C42.8714 113.055 36.7938 114.15 29.5665 114.15C25.8433 114.15 22.5034 113.876 19.5468 113.329C16.6996 112.891 14.1262 112.288 11.8266 111.522C9.52701 110.755 7.44641 109.879 5.58482 108.894C3.72322 107.799 1.86159 106.704 0 105.609L0 85.2405C1.31407 86.7736 2.90189 88.3067 4.76348 89.8398C6.73458 91.3728 8.86992 92.7417 11.1695 93.9462C13.5787 95.1508 16.1521 96.1363 18.8898 96.9029C21.6274 97.6694 24.4198 98.0527 27.2669 98.0527C32.5232 98.0527 36.6844 97.2861 39.7505 95.7531C42.8167 94.22 45.1163 92.3584 46.6494 90.1683C48.292 87.8687 49.3323 85.4595 49.7703 82.9409C50.3179 80.4223 50.5916 78.1774 50.5916 76.2063V16.909H23.8175V0.647461Z"
                fill="#E1FA00"
              />
            </svg>
          </div>
          <div className={styles.textRow}>
            <svg
              width="85"
              height="113"
              viewBox="0 0 85 113"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M42.0502 0.479004C46.8684 0.479004 51.7414 0.86227 56.6691 1.62881C61.7064 2.39535 66.2509 4.03794 70.3026 6.55657C74.3543 8.96569 77.6943 12.4151 80.3224 16.9048C82.9505 21.2851 84.2645 27.1984 84.2645 34.6447C84.2645 46.0333 80.5961 54.52 73.2592 60.1048C66.0319 65.5801 56.0669 68.3177 43.3643 68.3177H17.7399V88.85V112.503H0L0 0.479004H42.0502ZM64.7179 41.8721C64.1703 43.9527 63.0205 45.7596 61.2684 47.2926C50.3742 56.825 30.7968 56.5076 17.7784 54.7911L17.7399 16.7406H41.0646C45.9924 16.7406 50.0441 17.1239 53.2197 17.8904C56.3954 18.6569 58.914 19.8068 60.7756 21.3398C62.6372 22.8729 63.8966 24.7892 64.5536 27.0889C65.3202 29.279 65.7034 31.7976 65.7034 34.6447C65.7034 37.2729 65.3749 39.682 64.7179 41.8721Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              width="115"
              height="115"
              viewBox="0 0 115 115"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M57.7064 0C66.5764 0 74.5156 1.53308 81.5239 4.59923C88.5323 7.55589 94.4456 11.6623 99.2639 16.9186C104.192 22.0654 107.915 28.1429 110.433 35.1513C113.062 42.1596 114.376 49.606 114.376 57.4904C114.376 65.3748 113.062 72.8212 110.433 79.8296C107.915 86.8379 104.192 92.9702 99.2639 98.2265C94.4456 103.373 88.5323 107.48 81.5239 110.546C74.5156 113.503 66.5764 114.981 57.7064 114.981C48.8365 114.981 40.8974 113.503 33.889 110.546C26.8807 107.48 20.9126 103.373 15.9848 98.2265C11.0571 92.9702 7.27917 86.8379 4.65104 79.8296C2.13241 72.8212 0.873047 65.3748 0.873047 57.4904C0.873047 49.606 2.13241 42.1596 4.65104 35.1513C7.27917 28.1429 11.0571 22.0654 15.9848 16.9186C20.9126 11.6623 26.8807 7.55589 33.889 4.59923C40.8974 1.53308 48.8365 0 57.7064 0ZM57.7064 98.8835C64.0578 98.8835 69.5878 97.8432 74.2965 95.7626C79.1148 93.682 83.1118 90.7801 86.2874 87.0569C89.5726 83.3337 91.9817 78.9535 93.5148 73.9163C95.1574 68.879 95.9786 63.4037 95.9786 57.4904C95.9786 51.5771 95.1574 46.1566 93.5148 41.2289C91.9817 36.1916 89.5726 31.8114 86.2874 28.0882C83.1118 24.365 79.1148 21.4631 74.2965 19.3825C69.5878 17.3019 64.0578 16.2616 57.7064 16.2616C51.3551 16.2616 45.7703 17.3019 40.9521 19.3825C36.1338 21.4631 32.0821 24.365 28.7969 28.0882C25.6213 31.8114 23.2122 36.1916 21.5696 41.2289C20.0366 46.1566 19.27 51.5771 19.27 57.4904C19.27 63.4037 20.0366 68.879 21.5696 73.9163C23.2122 78.9535 25.6213 83.3337 28.7969 87.0569C32.0821 90.7801 36.1338 93.682 40.9521 95.7626C45.7703 97.8432 51.3551 98.8835 57.7064 98.8835Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              width="79"
              height="113"
              viewBox="0 0 79 113"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4157 16.7406V47.6212H72.6209V63.8827H18.4157V96.4059H78.5343V112.503H0.675781V0.479004H78.5343V16.7406H18.4157Z"
                fill="#E1FA00"
              />
            </svg>
            <svg
              width="92"
              height="113"
              viewBox="0 0 92 113"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M91.9236 0.479004V112.503H75.9905L18.1716 30.5383V112.503H0.431641V0.479004H16.8575L74.1837 83.2652V0.479004H91.9236Z"
                fill="#E1FA00"
              />
            </svg>
          </div>
        </div>
        <div className={styles.headerRight}>
        </div>
      </div>
      <div className={styles.levels}>
        <div className={styles.level}>
          <h2>MBO</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clip-path="url(#clip0_680_45)">
              <path
                d="M0 16.0482L1.84337 18L15.2892 4.55422V16.3735H18V0H1.62651V2.71084H13.4458L0 16.0482Z"
                fill="#E45D2E"
              />
            </g>
            <defs>
              <clipPath id="clip0_680_45">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className={styles.level}>
          <h2>HBO</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clip-path="url(#clip0_680_45)">
              <path
                d="M0 16.0482L1.84337 18L15.2892 4.55422V16.3735H18V0H1.62651V2.71084H13.4458L0 16.0482Z"
                fill="#E45D2E"
              />
            </g>
            <defs>
              <clipPath id="clip0_680_45">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className={styles.level}>
          <h2>WO</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clip-path="url(#clip0_680_45)">
              <path
                d="M0 16.0482L1.84337 18L15.2892 4.55422V16.3735H18V0H1.62651V2.71084H13.4458L0 16.0482Z"
                fill="#E45D2E"
              />
            </g>
            <defs>
              <clipPath id="clip0_680_45">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </>
  );
}
