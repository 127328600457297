import styles from "./style.module.scss";
import { ReactComponent as Logo } from "../../logo.svg";
import { useState } from "react";
import Footer from "../footer";
import { Link } from "react-router-dom";

export default function Navbar() {

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.navLeft}>
          <Link to="/">
              <Logo className={styles.navLogo} />
          </Link>
        </div>
        <div className={styles.navRight}>
          <Link className={styles.navItem} to="/#calculator">
            Calculator
          </Link>
          <Link className={styles.navItem} to="/wijzewoordenweb">
            WijzeWoordenWeb
          </Link>
          <button className={`${styles.button} ${styles.navItem}`}>
            Partner Worden
          </button>
          <button onClick={
            () => setMenuOpen(!menuOpen)
          }>
            {!menuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="13"
                viewBox="0 0 34 13"
                fill="none"
              >
                <rect width="34" height="1" fill="#E1FA00" />
                <rect y="12" width="34" height="1" fill="#E1FA00" />
              </svg>
            ) : (
              <svg width="34" height="34" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0543 10.7228L0 20.7771L0.629547 21.4067L10.6838 11.3524L21.0362 21.7048L21.6658 21.0752L11.3134 10.7228L21.4047 0.631504L20.7751 0.00195312L10.6838 10.0933L0.890625 0.30006L0.261078 0.929611L10.0543 10.7228Z" fill="#E1FA00"/>
              </svg>
            )}
          </button>
        </div>
      </div>
      {menuOpen && 
        <div className={styles.mobileMenu}>

          <Footer mobileMenu />
        </div>
      }
    </>
  );
}
