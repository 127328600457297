import { useState } from "react";
import styles from "./style.module.scss";

export default function Calculator() {
  const [studyDepth, setStudyDepth] = useState(0);
  const [interestPercentage, setInterestPercentage] = useState(2.56);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [waitingMonths, setWaitingMonths] = useState(0);
  const [extraPaid, setExtraPaid] = useState(0);
  const [positiveYears, setPositiveYears] = useState(0);
  const [positiveMonths, setPositiveMonths] = useState(0);
  const [setCalculated, setSetCalculated] = useState(false);

  const calculateStudyDepth = () => {
    let interestPerYear = 1 + interestPercentage / 100;
    let interestPerMonth = Math.pow(interestPerYear, 1 / 12);
    let leftOver;
    if (waitingMonths > 0) {
      leftOver = studyDepth * Math.pow(interestPerMonth, waitingMonths);
    } else {
      leftOver = studyDepth;
    }

    let timeNeeded = 0;
    let extraPaid = 0;
    let totalPaid = 0;

    for (let i = 0; i < 1000; i++) {
      leftOver = (leftOver - monthlyPayment) * interestPerMonth;
      if (leftOver < 0) {
        timeNeeded = i + 1;
        totalPaid = i * monthlyPayment + (leftOver + monthlyPayment);
        extraPaid = totalPaid - studyDepth;
        break;
      }
    }
    let positiveYears = Math.floor(timeNeeded / 12);
    let positiveMonths = timeNeeded % 12;
    setExtraPaid(extraPaid);
    setPositiveYears(positiveYears);
    setPositiveMonths(positiveMonths);
    setSetCalculated(true);
  };

  const formatEuro = (number: number) => {
    const formattedAmount: string = `€${number.toLocaleString("nl-NL", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    return formattedAmount;
  };

  const handleInterestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Replace commas with periods before parsing the value
    const inputValue = e.target.value.replace(",", ".");
    setInterestPercentage(parseFloat(inputValue));
  };
  return (
    <div className={styles.container}>
        <div className={styles.depthCalculator}>
          <div className={styles.description}>
            <h1>Studieschuld <span>Calculator</span></h1>
            <p>Door deze calculator in te vullen kom jij erachter hoe lang het duurt voordat jouw studieschuld is afbetaald en hoeveel extra jij hebt betaald door de rente.</p>
          </div>
          <div className={styles.depthContent}>
            <div className={styles.calculatorBox}>
              <div className={styles.leftSide}>
                <div className={styles.inputBox}>
                  <label className={styles.inputTitle}>
                    Studieschuld (in euro's):
                  </label>
                  <input
                    className={`${styles.input} ${
                      studyDepth < 0 ? styles.inputError : ""
                    }`}
                    type="number"
                    placeholder="60000"
                    value={studyDepth}
                    onChange={(e) => setStudyDepth(parseInt(e.target.value))}
                  />
                  {studyDepth < 0 && <p>Studieschuld moet hoger zijn dan 0</p>}
                </div>
                <hr className={styles.line}></hr>
                <div className={styles.inputBox}>
                  <label className={styles.inputTitle}>
                    Rentepercentage:
                  </label>
                  <input
                    className={styles.input}
                    type="number"
                    placeholder="2,54"
                    value={interestPercentage}
                    min="0"
                    step="0.01"
                    onChange={handleInterestChange}
                  />
                  {studyDepth < 0 && <p>Studieschuld moet hoger zijn dan 0</p>}
                </div>
                <hr className={styles.line}></hr>
                <div className={styles.inputBox}>
                  <label className={styles.inputTitle}>
                    Maandelijks bedrag (in euro's):
                  </label>
                  <input
                    className={`${styles.input} ${
                      monthlyPayment < 0 ? styles.inputError : ""
                    }`}
                    type="number"
                    value={monthlyPayment}
                    min="0"
                    onChange={(e) =>
                      setMonthlyPayment(parseInt(e.target.value))
                    }
                  />
                  {monthlyPayment < 0 && (
                    <p>Maandelijks bedrag moet hoger zijn dan 0</p>
                  )}
                </div>
                <hr className={styles.line}></hr>
                <div className={styles.inputBox}>
                  <label className={styles.inputTitle}>
                    Maanden wachten met aflossen (0-24):
                  </label>
                  <input
                    className={`${styles.input} ${
                      waitingMonths < 0 ? styles.inputError : ""
                    }`}
                    type="number"
                    value={waitingMonths}
                    min="0"
                    max="24"
                    onChange={(e) => setWaitingMonths(parseInt(e.target.value))}
                  />
                  {waitingMonths < 0 && (
                    <p>Maanden wachten moet hoger zijn dan 0</p>
                  )}
                </div>
                <hr className={styles.line}></hr>
              </div>

              <div className={styles.rightSide}>
                <button
                  className={styles.transparentButton}
                  onClick={calculateStudyDepth}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <circle
                      cx="17.6006"
                      cy="17.6006"
                      r="17.6006"
                      fill="#E1FA00"
                    />
                    <path
                      d="M25.5859 10.7562C25.5859 10.2602 25.1838 9.85804 24.6878 9.85804L16.6041 9.85804C16.1081 9.85804 15.706 10.2602 15.706 10.7562C15.706 11.2523 16.1081 11.6544 16.6041 11.6544H23.7896V18.8398C23.7896 19.3359 24.1917 19.738 24.6878 19.738C25.1838 19.738 25.5859 19.3359 25.5859 18.8398L25.5859 10.7562ZM11.389 25.3252L25.3229 11.3913L24.0526 10.1211L10.1188 24.055L11.389 25.3252Z"
                      fill="#213305"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {setCalculated ? (
              <div className={styles.box}>
                <h1 className={styles.resultTitle}>Resultaat</h1>
                <h3>Schuld volledig afbetaald:</h3>
                <p className={styles.outputText}>
                  {positiveYears} jaar en {positiveMonths} maand(en)
                </p>
                <h3>Extra betaald door de rente:</h3>
                <p className={styles.outputText}>
                  {formatEuro(Math.round(extraPaid * 100) / 100)}
                </p>
              </div>
            ) : (
              <div className={styles.box}>
                <h1 className={styles.resultTitle}>Resultaat</h1>
                <h3>Schuld volledig afbetaald:</h3>
                <p className={styles.outputText}>
                  Geen resultaat
                </p>
                <h3>Extra betaald door de rente:</h3>
                <p className={styles.outputText}>
                  Geen resultaat
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
  );
}