
import Navbar from "../../components/navbar";
import Header from "../../components/header";
import Calculator from "../../components/calculator";
import Instances from "../../components/instances";
import Quote from "../../components/quote";
import Sponsor from "../../components/sponsor";
import Footer from "../../components/footer";

export default function Home() {
  return (
    <>
      <Navbar />
      <Header />
      <div id="calculator" />
      <Calculator />
      <div id="instances" />
      <Instances />
      <div id="quote" />
      <Quote />
      <div id="sponsor" />
      <Sponsor />
      <Footer mobileMenu={false} />
    </>
  );
}
