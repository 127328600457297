import styles from "./style.module.scss";
import FooterLogo from "./FooterLogo";
import FooterLogoSmall from "./FooterLogoSmall";
import { Link } from "react-router-dom";

export default function Footer({ mobileMenu }: { mobileMenu: boolean }) {
  return (
    <div className={styles.footerSection} style={mobileMenu ? { height: '100%' } : {}}>
      <div className={styles.footerContent}>
        {!mobileMenu && (
          <p className={styles.copyright}>©ZekerVanJePoen</p>
        )}
        <div className={styles.footerTabs}>
          <div className={styles.footerTab}>
            <p>MBO</p>
            <p>HBO</p>
            <p>WO</p>
            <p>Calculator</p>
          </div>
          <div className={styles.footerTab}>
            {!mobileMenu && (
              <p>Instanties</p>
            )}
            <p>Cijfers</p>
            <p>Over Ons</p>
            <Link to="/wijzewoordenweb">
              <p>WijzeWoordenWeb</p>
            </Link>
          </div>
          <div className={styles.footerTab}>
            <p>Sponsoren</p>
            <p>Word Sponsor</p>
            <p>Contact</p>
          </div>
          {!mobileMenu && (
            <div className={styles.footerFeedback}>
              <h1>Feedback</h1>
              <input type="text" placeholder="Wat mist er nog op de website" />
            </div>
          )}
        </div>
        <div className={styles.logoSection}>
          <div className={styles.desktopLogo}>
            <FooterLogo />
          </div>
          <div className={styles.mobileLogo}>
            <FooterLogoSmall />
          </div>
        </div>
      </div>
    </div>
  );
}
