import styles from "./style.module.scss";

export default function Slider() {
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.scrollParent}>
        <div className={`${styles.scrollElement} ${styles.primary}`}>
          <h1>
            Tip van <span>Ties</span>
          </h1>
          <h1>
            Tip van <span>Luca</span>
          </h1>
          <h1>
            Tip van <span>Anna</span>
          </h1>
          <h1>
            Tip van <span>Mikky</span>
          </h1>
        </div>
        <div className={`${styles.scrollElement} ${styles.secondary}`}>
          <h1>
            Tip van <span>Ties</span>
          </h1>
          <h1>
            Tip van <span>Luca</span>
          </h1>
          <h1>
            Tip van <span>Anna</span>
          </h1>
          <h1>
            Tip van <span>Mikky</span>
          </h1>
        </div>
      </div>
    </div>
  );
}
