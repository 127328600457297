import styles from "./style.module.scss";

export default function FooterLogo() {
  return (
    <div className={styles.footerLogo}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="45"
        viewBox="0 0 80 45"
        fill="none"
      >
        <path
          d="M79.8838 89.2302V104.142H0V90.5996L58.2771 15.4328H1.67376V0.368958H78.21V14.0633L20.5415 89.2302H79.8838Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73"
        height="45"
        viewBox="0 0 73 45"
        fill="none"
      >
        <path
          d="M17.1852 15.4328V44.0388H67.3979V59.1026H17.1852V89.2302H72.8756V104.142H0.751953V0.368958H72.8756V15.4328H17.1852Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90"
        height="45"
        viewBox="0 0 90 45"
        fill="none"
      >
        <path
          d="M0.746094 104.142V0.368958H17.1793V104.142H0.746094ZM89.1508 104.142H68.7614L19.766 49.8208L65.5661 0.368958H86.4119L40.9162 49.6687L89.1508 104.142Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73"
        height="45"
        viewBox="0 0 73 45"
        fill="none"
      >
        <path
          d="M16.9215 15.4328V44.0388H67.1342V59.1026H16.9215V89.2302H72.6119V104.142H0.488281V0.368958H72.6119V15.4328H16.9215Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83"
        height="45"
        viewBox="0 0 83 45"
        fill="none"
      >
        <path
          d="M82.04 104.142H63.933L37.9137 63.2109H16.9156V104.142H0.482422V0.368958H37.9137C43.4929 0.368958 48.6663 0.876151 53.434 1.89055C58.2016 2.8035 62.3099 4.47727 65.7589 6.91183C69.3093 9.24495 72.0481 12.4403 73.9755 16.4979C76.0043 20.454 77.0187 25.526 77.0187 31.7138C77.0187 35.9743 76.4608 39.7783 75.3449 43.1258C74.3306 46.3719 72.8597 49.2122 70.9323 51.6467C69.005 53.9799 66.6719 55.9072 63.933 57.4288C61.2956 58.9504 58.3538 60.1677 55.1077 61.0806L82.04 104.142ZM31.3708 48.1471C35.1241 48.1471 38.7252 48.0964 42.1742 47.9949C45.6231 47.8935 48.6663 47.3356 51.3037 46.3212C53.9412 45.3068 56.0207 43.6837 57.5423 41.4521C59.1653 39.2204 59.9768 35.9743 59.9768 31.7138C59.9768 28.062 59.4189 25.171 58.3031 23.0407C57.1872 20.9105 55.6656 19.2875 53.7383 18.1716C51.8109 17.0558 49.5285 16.3457 46.8911 16.0414C44.3551 15.6356 41.6162 15.4328 38.6745 15.4328H16.9156V48.1471H31.3708Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="103"
        height="44"
        viewBox="0 0 103 44"
        fill="none"
      >
        <path
          d="M90.3935 0.864197C97.3453 0.864197 102.907 5.73049 102.907 14.3044C102.907 35.3917 77.185 60.65 40.5719 105.605H38.7181L26.2048 36.0869C22.9606 18.0121 17.8626 11.9871 9.98384 11.9871C7.43483 11.9871 4.42236 12.9141 0.714708 14.0727L0.0195312 10.365L27.1317 0.864197L30.6076 1.09593C39.8767 6.6574 42.8892 19.8659 45.67 36.5503L53.0853 83.8228C76.7215 54.8568 89.4666 39.3311 89.4666 28.6716C89.4666 19.1707 80.1975 18.0121 80.1975 9.43814C80.1975 4.10839 85.0637 0.864197 90.3935 0.864197Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="103"
        height="45"
        viewBox="0 0 103 45"
        fill="none"
      >
        <path
          d="M102.642 104.142H85.448L74.949 78.2747H28.3882L17.737 104.142H0.542969L43.452 0.368958H59.8852L102.642 104.142ZM68.8626 63.2109L51.6686 20.9105L34.4746 63.2109H68.8626Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="45"
        viewBox="0 0 85 45"
        fill="none"
      >
        <path
          d="M84.8623 0.368958V104.142H70.1027L16.5426 28.2142V104.142H0.109375V0.368958H15.3253L68.429 77.0574V0.368958H84.8623Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="45"
        viewBox="0 0 64 45"
        fill="none"
      >
        <path
          d="M22.6101 0.368958H63.8453V70.3624C63.8453 74.7243 63.2367 79.0355 62.0194 83.2959C60.8021 87.455 58.7733 91.2082 55.933 94.5557C53.0926 97.8018 49.3901 100.439 44.8253 102.468C40.2605 104.497 34.6306 105.511 27.9356 105.511C24.4866 105.511 21.3928 105.258 18.6539 104.75C16.0165 104.345 13.6326 103.787 11.5024 103.077C9.37215 102.367 7.44481 101.555 5.72033 100.642C3.99586 99.6277 2.27135 98.6133 0.546875 97.599V78.7312C1.76415 80.1513 3.23502 81.5715 4.95949 82.9916C6.78541 84.4118 8.76347 85.6798 10.8937 86.7956C13.1254 87.9114 15.5093 88.8244 18.0453 89.5345C20.5813 90.2446 23.1679 90.5996 25.8054 90.5996C30.6745 90.5996 34.5292 89.8895 37.3695 88.4694C40.2098 87.0492 42.3401 85.3247 43.7602 83.2959C45.2818 81.1657 46.2455 78.934 46.6513 76.6009C47.1585 74.2678 47.412 72.1883 47.412 70.3624V15.4328H22.6101V0.368958Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73"
        height="45"
        viewBox="0 0 73 45"
        fill="none"
      >
        <path
          d="M17.0446 15.4328V44.0388H67.2572V59.1026H17.0446V89.2302H72.735V104.142H0.611328V0.368958H72.735V15.4328H17.0446Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="45"
        viewBox="0 0 79 45"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.5838 0.368958C44.0471 0.368958 48.5611 0.723993 53.1259 1.43407C57.7921 2.14415 62.0019 3.66575 65.7552 5.99886C69.5084 8.23054 72.6024 11.4259 75.0369 15.5849C77.4715 19.6425 78.6887 25.1203 78.6887 32.0182C78.6887 42.5679 75.2905 50.4295 68.494 55.6029C61.799 60.6749 52.5681 63.2109 40.801 63.2109H17.0641V82.2308V104.142H0.630859V0.368958H39.5838ZM60.5818 38.7132C60.0746 40.6405 59.0094 42.3143 57.3864 43.7344C47.2947 52.5647 29.1592 52.2706 17.0998 50.6806L17.0641 15.4328H38.6708C43.2356 15.4328 46.9888 15.7878 49.9306 16.4979C52.8723 17.208 55.2055 18.2731 56.9299 19.6932C58.6544 21.1134 59.821 22.8886 60.4296 25.0188C61.1397 27.0476 61.4947 29.3807 61.4947 32.0182C61.4947 34.4527 61.1904 36.6844 60.5818 38.7132Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="106"
        height="46"
        viewBox="0 0 106 46"
        fill="none"
      >
        <path
          d="M53.4597 0C61.6763 0 69.0307 1.42016 75.5229 4.26047C82.015 6.99934 87.4928 10.8033 91.9561 15.6724C96.5209 20.4401 99.9699 26.07 102.303 32.5622C104.738 39.0543 105.955 45.9522 105.955 53.2559C105.955 60.5595 104.738 67.4574 102.303 73.9496C99.9699 80.4417 96.5209 86.1223 91.9561 90.9914C87.4928 95.7591 82.015 99.5631 75.5229 102.403C69.0307 105.142 61.6763 106.512 53.4597 106.512C45.2431 106.512 37.8888 105.142 31.3966 102.403C24.9045 99.5631 19.376 95.7591 14.8112 90.9914C10.2464 86.1223 6.74677 80.4417 4.31222 73.9496C1.9791 67.4574 0.8125 60.5595 0.8125 53.2559C0.8125 45.9522 1.9791 39.0543 4.31222 32.5622C6.74677 26.07 10.2464 20.4401 14.8112 15.6724C19.376 10.8033 24.9045 6.99934 31.3966 4.26047C37.8888 1.42016 45.2431 0 53.4597 0ZM53.4597 91.6001C59.3432 91.6001 64.4659 90.6364 68.8278 88.7091C73.2912 86.7817 76.9937 84.0935 79.9355 80.6446C82.9787 77.1956 85.2104 73.1381 86.6305 68.4718C88.1521 63.8056 88.9129 58.7336 88.9129 53.2559C88.9129 47.7781 88.1521 42.7569 86.6305 38.1921C85.2104 33.5258 82.9787 29.4682 79.9355 26.0193C76.9937 22.5703 73.2912 19.8822 68.8278 17.9548C64.4659 16.0275 59.3432 15.0638 53.4597 15.0638C47.5762 15.0638 42.4028 16.0275 37.9394 17.9548C33.4761 19.8822 29.7228 22.5703 26.6796 26.0193C23.7379 29.4682 21.5062 33.5258 19.9846 38.1921C18.5645 42.7569 17.8544 47.7781 17.8544 53.2559C17.8544 58.7336 18.5645 63.8056 19.9846 68.4718C21.5062 73.1381 23.7379 77.1956 26.6796 80.6446C29.7228 84.0935 33.4761 86.7817 37.9394 88.7091C42.4028 90.6364 47.5762 91.6001 53.4597 91.6001Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="73"
        height="45"
        viewBox="0 0 73 45"
        fill="none"
      >
        <path
          d="M16.7067 15.4328V44.0388H66.9193V59.1026H16.7067V89.2302H72.3971V104.142H0.273438V0.368958H72.3971V15.4328H16.7067Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="45"
        viewBox="0 0 85 45"
        fill="none"
      >
        <path
          d="M85.0009 0.368958V104.142H70.2415L16.6813 28.2142V104.142H0.248047V0.368958H15.464L68.5678 77.0574V0.368958H85.0009Z"
          fill="#E1FA00"
        />
      </svg>
    </div>
  );
}
