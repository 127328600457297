import styles from "./style.module.scss";

export default function Articles() {
  return (
    <>
      <div className={styles.articles}>
        <div className={styles.article}>
          <div className={styles.left}>
            <img
              className={styles.image}
              src="https://uploads.top/api/v2/upload/c86246968e2f0f22"
              alt="Bibi's_image"
            />
          </div>
          <div className={styles.right}>
            <div>
              <h1>Bibi</h1>
              <h2>“Elke <span>
                dag
                <svg xmlns="http://www.w3.org/2000/svg" width="77" height="41" viewBox="0 0 77 41" fill="none">
                  <path d="M40.5505 39.9558C36.8544 39.9558 33.1583 39.9558 29.4622 39.9558C23.8349 39.9558 18.625 40.2423 13.6217 36.3066C9.30616 32.9119 4.81196 28.8497 2.24821 22.9403C0.802409 19.6078 1.57918 14.685 2.72343 11.4411C4.19446 7.27074 8.58382 5.42226 11.6258 4.07908C19.0702 0.79198 26.8683 1.00272 34.6262 1.00272C45.0766 1.00272 55.1613 1.52907 65.2459 5.60665C68.5155 6.92869 71.4485 8.78617 73.4671 12.7141C75.2121 16.1097 76.3996 19.381 75.8749 23.598C75.2354 28.7372 69.667 30.9582 66.5923 32.3179C62.4825 34.1355 58.3839 36.1369 54.015 36.1369C50.1148 36.1369 46.4566 37.8537 42.6415 38.5131C39.5535 39.0468 36.3871 39.2197 33.2798 39.5739C29.792 39.9715 26.2659 40.2521 22.7775 39.6588C17.1868 38.7079 11.0263 37.7927 6.11329 33.9304C2.16069 30.823 0.362349 27.8157 1.20274 21.6249" stroke="#E45D2E" stroke-linecap="round"/>
                </svg>
                </span> in de overlevingsmodus”</h2>
              <p className={styles.description}>In mijn studentenleven worstel ik elke dag met geldzorgen, waarbij elke euro telt. Studeren is niet alleen moeilijk op het gebied van kennis, maar ook een constante strijd om rond te komen.</p>
            </div>
            <div className={styles.bottomRow}>
              <p className={styles.date}>01.02.2023</p>
              <p className={styles.readMoreBtn}>Lees meer</p>
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.left}>
            <img
              className={styles.image}
              src="https://uploads.top/api/v2/upload/57d2fe022ffead46"
              alt="Timon's_image"
            />
          </div>
          <div className={styles.right}>
            <div>
              <h1>Timon</h1>
              <h2>“Alles kost <span>
                geld
                <svg xmlns="http://www.w3.org/2000/svg" width="88" height="35" viewBox="0 0 88 35" fill="none">
                  <path d="M46.3513 33.9626C42.1131 33.9626 37.8749 33.9626 33.6367 33.9626C27.184 33.9626 21.21 34.205 15.4729 30.8748C10.5244 28.0024 5.37104 24.5651 2.43128 19.5649C0.773429 16.7451 1.66413 12.5797 2.97619 9.8348C4.66298 6.30601 9.69611 4.74191 13.1842 3.60538C21.7205 0.823983 30.6623 1.0023 39.558 1.0023C51.5412 1.0023 63.1049 1.44767 74.6686 4.89794C78.4178 6.01659 81.781 7.58829 84.0956 10.9119C86.0966 13.7851 87.4582 16.5532 86.8565 20.1214C86.1232 24.4699 79.7381 26.3492 76.2125 27.4998C71.4999 29.0377 66.8003 30.7312 61.7905 30.7312C57.3183 30.7312 53.1235 32.1839 48.7489 32.7418C45.208 33.1934 41.5773 33.3397 38.0141 33.6394C34.0148 33.9759 29.9716 34.2133 25.9715 33.7113C19.5608 32.9067 12.4968 32.1323 6.86324 28.8642C2.33093 26.2349 0.268827 23.6902 1.23247 18.4519" stroke="#E3BBE4" stroke-linecap="round"/>
                </svg>
                </span> maar je hebt niet alles nodig”</h2>
              <p className={styles.description}>Elke uitgave telt, en ik leer dat niet alles noodzakelijk is. Het is een balans tussen wat ik nodig heb en wat ik me kan veroorloven. Geld is beperkt, maar slimme keuzes maken helpt.</p>
            </div>
            <div className={styles.bottomRow}>
              <p className={styles.date}>01.02.2023</p>
              <p className={styles.readMoreBtn}>Lees meer</p>
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.left}>
            <img
              className={styles.image}
              src="https://uploads.top/api/v2/upload/e00993ed57f50f3c"
              alt="Mikky's_image"
            />
          </div>
          <div className={styles.right}>
            <div>
              <h1>Mikky</h1>
              <h2>“Ik wil ook nog <span>
                leuke
                <svg xmlns="http://www.w3.org/2000/svg" width="77" height="41" viewBox="0 0 77 41" fill="none">
                  <path d="M40.5505 39.9558C36.8544 39.9558 33.1583 39.9558 29.4622 39.9558C23.8349 39.9558 18.625 40.2423 13.6217 36.3066C9.30616 32.9119 4.81196 28.8497 2.24821 22.9403C0.802409 19.6078 1.57918 14.685 2.72343 11.4411C4.19446 7.27074 8.58382 5.42226 11.6258 4.07908C19.0702 0.79198 26.8683 1.00272 34.6262 1.00272C45.0766 1.00272 55.1613 1.52907 65.2459 5.60665C68.5155 6.92869 71.4485 8.78617 73.4671 12.7141C75.2121 16.1097 76.3996 19.381 75.8749 23.598C75.2354 28.7372 69.667 30.9582 66.5923 32.3179C62.4825 34.1355 58.3839 36.1369 54.015 36.1369C50.1148 36.1369 46.4566 37.8537 42.6415 38.5131C39.5535 39.0468 36.3871 39.2197 33.2798 39.5739C29.792 39.9715 26.2659 40.2521 22.7775 39.6588C17.1868 38.7079 11.0263 37.7927 6.11329 33.9304C2.16069 30.823 0.362349 27.8157 1.20274 21.6249" stroke="#E1FA00" stroke-linecap="round"/>
                </svg>
                </span> dingen doen”</h2>
              <p className={styles.description}>Ondanks de krappe financiën zoek ik naar manieren om ook plezier te hebben. Elk uitje wordt zorgvuldig overwogen, maar ik ontdek dat geluk niet altijd een prijskaartje heeft, er is veel mogelijk binnen een beperkt budget.</p>
            </div>
            <div className={styles.bottomRow}>
              <p className={styles.date}>01.02.2023</p>
              <p className={styles.readMoreBtn}>Lees meer</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
