import Slider from "./Slider";
import styles from "./style.module.scss";

export default function Header() {
  return (
    <>
      <div className={styles.header}>
        <h1>Wijze<span>Woorden</span>Web</h1>
        <p>Tips door en voor studenten die leven met een studieschuld</p>
      </div>
      <Slider />
    </>
  )
}