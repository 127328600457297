import styles from "./style.module.scss";

export default function Instances() {
  return (
    <div className={styles.instancesSection}>
      <div className={styles.sectionHeader}>
        <h1>
          Grijp de <span>reddingsboeien</span>
        </h1>
      </div>
      <div className={styles.instances}>
        <a className={styles.instance} href="https://www.juridischloket.nl/professionals/nieuws/telefonie/" target="_blank" rel="noreferrer">
            <div className={styles.content}>
              <div className={styles.left}>
                <h1 className={styles.title}>Juridisch Loket</h1>
                <div className={styles.description}>
                  <p>Gratis advies</p>
                </div>
              </div>
              <div className={styles.right}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <circle cx="13.303" cy="13.303" r="13.303" fill="#E1FA00" />
                  <path
                    d="M19.3393 8.12961C19.3393 7.75468 19.0354 7.45074 18.6604 7.45074L12.5506 7.45074C12.1757 7.45074 11.8718 7.75468 11.8718 8.12961C11.8718 8.50453 12.1757 8.80847 12.5506 8.80847L17.9816 8.80847L17.9816 14.2394C17.9816 14.6143 18.2855 14.9183 18.6604 14.9183C19.0354 14.9183 19.3393 14.6143 19.3393 14.2394L19.3393 8.12961ZM8.60894 19.1412L19.1405 8.60964L18.1804 7.64958L7.64888 18.1811L8.60894 19.1412Z"
                    fill="#213305"
                  />
                </svg>
              </div>
            </div>
        </a>
        <a href="https://www.nibud.nl/tools/stappenplan-aan-de-slag-met-budgetteren/" target="_blank" rel="noreferrer" className={styles.instance}>
          <div className={styles.content}>
            <div className={styles.left}>
              <h1 className={styles.title}>NIBUD</h1>
              <div className={styles.description}>
                <p>Geldtips</p>
              </div>
            </div>
            <div className={styles.right}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <circle cx="13.303" cy="13.303" r="13.303" fill="#E3BBE4" />
                <path
                  d="M19.3393 8.12961C19.3393 7.75468 19.0354 7.45074 18.6604 7.45074L12.5506 7.45074C12.1757 7.45074 11.8718 7.75468 11.8718 8.12961C11.8718 8.50453 12.1757 8.80847 12.5506 8.80847L17.9816 8.80847L17.9816 14.2394C17.9816 14.6143 18.2855 14.9183 18.6604 14.9183C19.0354 14.9183 19.3393 14.6143 19.3393 14.2394L19.3393 8.12961ZM8.60894 19.1412L19.1405 8.60964L18.1804 7.64958L7.64888 18.1811L8.60894 19.1412Z"
                  fill="#213305"
                />
              </svg>
            </div>
          </div>
        </a>
        <a href="https://lsvb.nl/studentenlijn/" target="_blank" rel="noreferrer" className={styles.instance}>
          <div className={styles.content}>
            <div className={styles.left}>
              <h1 className={styles.title}>LSVb</h1>
              <div className={styles.description}>
                <p>Studentenlijn</p>
              </div>
            </div>
            <div className={styles.right}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <circle cx="13.303" cy="13.303" r="13.303" fill="#213305" />
                <path
                  d="M19.3393 8.12961C19.3393 7.75468 19.0354 7.45074 18.6604 7.45074L12.5506 7.45074C12.1757 7.45074 11.8718 7.75468 11.8718 8.12961C11.8718 8.50453 12.1757 8.80847 12.5506 8.80847L17.9816 8.80847L17.9816 14.2394C17.9816 14.6143 18.2855 14.9183 18.6604 14.9183C19.0354 14.9183 19.3393 14.6143 19.3393 14.2394L19.3393 8.12961ZM8.60894 19.1412L19.1405 8.60964L18.1804 7.64958L7.64888 18.1811L8.60894 19.1412Z"
                  fill="#F0F9ED"
                />
              </svg>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
