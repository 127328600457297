import styles from "./style.module.scss";

export default function FooterLogo() {
  return (
    <div className={styles.footerLogo}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="22"
        viewBox="0 0 25 22"
        fill="none"
      >
        <path
          d="M25.008 30.506V35.5475H-2V30.969L17.703 5.55577H-1.43412V0.46283H24.4421V5.09277L4.94491 30.506H25.008Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="22"
        viewBox="0 0 26 22"
        fill="none"
      >
        <path
          d="M6.23757 5.55577V15.2272H23.214V20.3201H6.23757V30.506H25.066V35.5475H0.681641V0.46283H25.066V5.55577H6.23757Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="22"
        viewBox="0 0 31 22"
        fill="none"
      >
        <path
          d="M0.417969 35.5475V0.46283H5.9739V35.5475H0.417969ZM30.3068 35.5475H23.4133L6.84844 17.1821L22.333 0.46283H29.3808L13.9991 17.1306L30.3068 35.5475Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="22"
        viewBox="0 0 25 22"
        fill="none"
      >
        <path
          d="M5.68093 5.55577V15.2272H22.6574V20.3201H5.68093V30.506H24.5094V35.5475H0.125V0.46283H24.5094V5.55577H5.68093Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="22"
        viewBox="0 0 29 22"
        fill="none"
      >
        <path
          d="M28.4352 35.5475H22.3134L13.5165 21.7091H6.41725V35.5475H0.861328V0.46283H13.5165C15.4028 0.46283 17.1519 0.634307 18.7638 0.977265C20.3757 1.28593 21.7647 1.85181 22.9307 2.67492C24.1311 3.46372 25.0571 4.54404 25.7087 5.91587C26.3946 7.25341 26.7376 8.9682 26.7376 11.0603C26.7376 12.5007 26.5489 13.7868 26.1717 14.9185C25.8287 16.016 25.3314 16.9763 24.6798 17.7994C24.0282 18.5882 23.2394 19.2398 22.3134 19.7543C21.4217 20.2687 20.4271 20.6802 19.3296 20.9889L28.4352 35.5475ZM11.3044 16.6162C12.5734 16.6162 13.7909 16.599 14.9569 16.5647C16.123 16.5304 17.1519 16.3418 18.0436 15.9989C18.9353 15.6559 19.6383 15.1072 20.1528 14.3527C20.7015 13.5981 20.9758 12.5007 20.9758 11.0603C20.9758 9.8256 20.7872 8.84817 20.41 8.12796C20.0327 7.40774 19.5183 6.85901 18.8667 6.48176C18.215 6.1045 17.4434 5.86443 16.5517 5.76154C15.6943 5.62436 14.7683 5.55577 13.7737 5.55577H6.41725V16.6162H11.3044Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="22"
        viewBox="0 0 35 22"
        fill="none"
      >
        <path
          d="M30.6601 0.968353C33.0104 0.968353 34.8907 2.6136 34.8907 5.51237C34.8907 12.6418 26.1944 21.1814 13.8159 36.3804H13.1891L8.95848 12.8768C7.86165 6.76589 6.13805 4.72892 3.47431 4.72892C2.61251 4.72892 1.59402 5.0423 0.340502 5.43403L0.105469 4.18051L9.27186 0.968353L10.447 1.0467C13.5808 2.92698 14.5993 7.39266 15.5395 13.0335L18.0465 29.0159C26.0377 19.2228 30.3467 13.9737 30.3467 10.3698C30.3467 7.15762 27.2129 6.7659 27.2129 3.86713C27.2129 2.06519 28.8581 0.968353 30.6601 0.968353Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="22"
        viewBox="0 0 35 22"
        fill="none"
      >
        <path
          d="M34.9328 35.5475H29.1197L25.5701 26.8021H9.82827L6.22721 35.5475H0.414062L14.9212 0.46283H20.4771L34.9328 35.5475ZM23.5123 21.7091L17.6992 7.40775L11.886 21.7091H23.5123Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="22"
        viewBox="0 0 30 22"
        fill="none"
      >
        <path
          d="M29.4511 0.46283V35.5475H24.461L6.3528 9.87705V35.5475H0.796875V0.46283H5.94125L23.8951 26.3905V0.46283H29.4511Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M7.52186 0.46283H21.4631V24.127C21.4631 25.6017 21.2574 27.0593 20.8458 28.4997C20.4343 29.9058 19.7483 31.1748 18.788 32.3065C17.8278 33.404 16.5759 34.2957 15.0326 34.9816C13.4893 35.6675 11.5859 36.0105 9.32237 36.0105C8.15631 36.0105 7.1103 35.9247 6.18432 35.7533C5.29262 35.6161 4.48667 35.4275 3.76646 35.1874C3.04625 34.9473 2.39463 34.6729 1.8116 34.3643C1.22857 34.0213 0.64553 33.6784 0.0625 33.3354V26.9564C0.47405 27.4365 0.971337 27.9167 1.55437 28.3968C2.17169 28.877 2.84046 29.3056 3.56067 29.6829C4.31518 30.0602 5.12116 30.3688 5.97855 30.6089C6.83595 30.849 7.71048 30.969 8.60217 30.969C10.2484 30.969 11.5516 30.7289 12.5119 30.2488C13.4722 29.7686 14.1924 29.1856 14.6725 28.4997C15.187 27.7795 15.5128 27.025 15.65 26.2362C15.8215 25.4474 15.9072 24.7443 15.9072 24.127V5.55577H7.52186V0.46283Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="22"
        viewBox="0 0 25 22"
        fill="none"
      >
        <path
          d="M5.67313 5.55577V15.2272H22.6496V20.3201H5.67313V30.506H24.5015V35.5475H0.117188V0.46283H24.5015V5.55577H5.67313Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="22"
        viewBox="0 0 28 22"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.031 0.46283C15.54 0.46283 17.0661 0.582864 18.6094 0.822936C20.187 1.06301 21.6103 1.57745 22.8793 2.36625C24.1482 3.12076 25.1943 4.20108 26.0174 5.60721C26.8405 6.97904 27.252 8.83102 27.252 11.1631C27.252 14.7299 26.1031 17.3878 23.8053 19.1369C21.5417 20.8517 18.4208 21.7091 14.4425 21.7091H6.41727V28.1396V35.5475H0.861328V0.46283H14.031ZM21.1302 13.4267C20.9587 14.0783 20.5986 14.6442 20.0499 15.1243C16.6379 18.1097 10.5065 18.0103 6.42933 17.4727L6.41727 5.55577H13.7223C15.2656 5.55577 16.5345 5.6758 17.5291 5.91587C18.5237 6.15594 19.3125 6.51605 19.8955 6.99619C20.4786 7.47634 20.873 8.07651 21.0788 8.79672C21.3188 9.48264 21.4388 10.2714 21.4388 11.1631C21.4388 11.9862 21.336 12.7407 21.1302 13.4267Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="22"
        viewBox="0 0 36 22"
        fill="none"
      >
        <path
          d="M18.1238 0C20.9017 0 23.3882 0.480142 25.5831 1.44043C27.7781 2.36641 29.63 3.65251 31.1391 5.29871C32.6824 6.91062 33.8484 8.81404 34.6372 11.009C35.4603 13.2039 35.8719 15.536 35.8719 18.0053C35.8719 20.4746 35.4603 22.8068 34.6372 25.0017C33.8484 27.1966 32.6824 29.1172 31.1391 30.7634C29.63 32.3753 27.7781 33.6614 25.5831 34.6217C23.3882 35.5477 20.9017 36.0107 18.1238 36.0107C15.3458 36.0107 12.8594 35.5477 10.6644 34.6217C8.4695 33.6614 6.60036 32.3753 5.05705 30.7634C3.51374 29.1172 2.33054 27.1966 1.50744 25.0017C0.718636 22.8068 0.324219 20.4746 0.324219 18.0053C0.324219 15.536 0.718636 13.2039 1.50744 11.009C2.33054 8.81404 3.51374 6.91062 5.05705 5.29871C6.60036 3.65251 8.4695 2.36641 10.6644 1.44043C12.8594 0.480142 15.3458 0 18.1238 0ZM18.1238 30.9692C20.1129 30.9692 21.8449 30.6434 23.3196 29.9917C24.8286 29.3401 26.0804 28.4313 27.075 27.2652C28.1039 26.0992 28.8584 24.7273 29.3385 23.1497C29.853 21.5721 30.1102 19.8573 30.1102 18.0053C30.1102 16.1534 29.853 14.4557 29.3385 12.9124C28.8584 11.3348 28.1039 9.96295 27.075 8.79689C26.0804 7.63083 24.8286 6.72199 23.3196 6.07037C21.8449 5.41875 20.1129 5.09294 18.1238 5.09294C16.1346 5.09294 14.3855 5.41875 12.8765 6.07037C11.3675 6.72199 10.0985 7.63083 9.06965 8.79689C8.07508 9.96295 7.32059 11.3348 6.80615 12.9124C6.32601 14.4557 6.08592 16.1534 6.08592 18.0053C6.08592 19.8573 6.32601 21.5721 6.80615 23.1497C7.32059 24.7273 8.07508 26.0992 9.06965 27.2652C10.0985 28.4313 11.3675 29.3401 12.8765 29.9917C14.3855 30.6434 16.1346 30.9692 18.1238 30.9692Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="22"
        viewBox="0 0 25 22"
        fill="none"
      >
        <path
          d="M5.5911 5.55577V15.2272H22.5675V20.3201H5.5911V30.506H24.4195V35.5475H0.0351562V0.46283H24.4195V5.55577H5.5911Z"
          fill="#E1FA00"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="22"
        viewBox="0 0 29 22"
        fill="none"
      >
        <path
          d="M29.4179 0.46283V35.5475H24.4278L6.31961 9.87705V35.5475H0.763672V0.46283H5.90806L23.862 26.3905V0.46283H29.4179Z"
          fill="#E1FA00"
        />
      </svg>
    </div>
  );
}
