const titleMap: any = {
  "/": "Home",
  "/wijzewoordenweb": "WijzeWoordenWeb",
};
/**
 * Returns the title of a page based on its route path.
 * @param {string} path - The path of the page route.
 * @returns {string} The title of the page with the site name appended.
 */
export const getTitleFromRoute = (path: string) => {
  if (titleMap[path]) {
    return `${titleMap[path]} | Zeker Van je Poen`;
  }

  return "Zeker Van je Poen";
};