import { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routes";
import './App.css';
import './globals.scss'

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route>
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
