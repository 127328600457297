import styles from "./style.module.scss";

export default function FirstSlider() {
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.scrollParent}>
        <div className={`${styles.scrollElement} ${styles.primary}`}>
          <h1>
            Gemeente <span>Haarlem</span>
          </h1>
          <h1>
            Gemeente <span>Amsterdam</span>
          </h1>
          <h1>
            Gemeente <span>Rotterdam</span>
          </h1>
        </div>
        <div className={`${styles.scrollElement} ${styles.secondary}`}>
          <h1>
            Gemeente <span>Haarlem</span>
          </h1>
          <h1>
            Gemeente <span>Amsterdam</span>
          </h1>
          <h1>
            Gemeente <span>Rotterdam</span>
          </h1>
        </div>
      </div>
    </div>
  );
}
