import styles from "./style.module.scss";

export default function Sponsor() {
  return (
    <div className={styles.sponsorContainer}>
      <h1 className={styles.title}>
        Wordt <span>sponsor</span>
      </h1>
      <div className={styles.sponsorCards}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.cardTop}>
              <h1>Stuivertje</h1>
              <h2>€5.000</h2>
              <hr />
              <div className={styles.tags}>
                <div className={styles.tag}>
                  <p>Logo op Site</p>
                </div>
                <div className={styles.tag}>
                  <p>Vermelding socials</p>
                </div>
              </div>
            </div>
            <div className={styles.cardBottom}>
              <div className={styles.sponsorStar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 83 88"
                  fill="none"
                >
                  <path
                    d="M41.5037 0.725586L43.1088 39.0706L66.946 8.99228L45.7059 40.9575L82.6702 30.6348L46.6979 44.0106L82.6702 57.3863L45.7059 47.0636L66.946 79.0288L43.1088 48.9505L41.5037 87.2955L39.8986 48.9505L16.0615 79.0288L37.3015 47.0636L0.337269 57.3863L36.3095 44.0106L0.337269 30.6348L37.3015 40.9575L16.0615 8.99228L39.8986 39.0706L41.5037 0.725586Z"
                    fill="#E1FA00"
                  />
                </svg>
              </div>
              <div className={styles.sponsorButton}>
                <p>Aanmelden</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <circle cx="12.6048" cy="12.3744" r="12.183" fill="#E1FA00" />
                  <path
                    d="M18.1337 7.63543C18.1337 7.29207 17.8554 7.01372 17.512 7.01372L11.9167 7.01372C11.5733 7.01372 11.295 7.29207 11.295 7.63543C11.295 7.97879 11.5733 8.25713 11.9167 8.25713H16.8903V13.2308C16.8903 13.5742 17.1687 13.8525 17.512 13.8525C17.8554 13.8525 18.1337 13.5742 18.1337 13.2308L18.1337 7.63543ZM8.3068 17.7199L17.9516 8.07504L17.0724 7.19581L7.42757 16.8407L8.3068 17.7199Z"
                    fill="#213305"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.cardTop}>
              <h1>Dubbeltje</h1>
              <h2>€10.000</h2>
              <hr />
              <div className={styles.tags}>
                <div className={styles.tag}>
                  <p>Logo op Site</p>
                </div>
                <div className={styles.tag}>
                  <p>Vermelding socials</p>
                </div>
                <div className={styles.tag}>
                  <p>Blogposts</p>
                </div>
              </div>
            </div>
            <div className={styles.cardBottom}>
              <div className={styles.sponsorStar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 84 88"
                  fill="none"
                >
                  <path
                    d="M41.9334 0.608398L43.5385 38.9534L67.3757 8.87509L46.1356 40.8403L83.0999 30.5176L47.1276 43.8934L83.0999 57.2692L46.1356 46.9464L67.3757 78.9116L43.5385 48.8333L41.9334 87.1783L40.3283 48.8333L16.4911 78.9116L37.7312 46.9464L0.766956 57.2692L36.7392 43.8934L0.766956 30.5176L37.7312 40.8403L16.4911 8.87509L40.3283 38.9534L41.9334 0.608398Z"
                    fill="#E3BBE4"
                  />
                </svg>
              </div>
              <div className={styles.sponsorButton}>
                <p>Aanmelden</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <circle cx="12.7806" cy="12.6664" r="12.183" fill="#E3BBE4" />
                  <path
                    d="M18.3076 7.92839C18.3076 7.58503 18.0292 7.30669 17.6859 7.30669L12.0905 7.30669C11.7471 7.30669 11.4688 7.58503 11.4688 7.92839C11.4688 8.27175 11.7471 8.5501 12.0905 8.5501H17.0642V13.5238C17.0642 13.8671 17.3425 14.1455 17.6859 14.1455C18.0292 14.1455 18.3076 13.8671 18.3076 13.5238L18.3076 7.92839ZM8.48063 18.0129L18.1255 8.36801L17.2462 7.48878L7.6014 17.1336L8.48063 18.0129Z"
                    fill="#213305"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.cardTop}>
              <h1>Kwartje</h1>
              <h2>€25.000</h2>
              <hr />
              <div className={styles.tags}>
                <div className={styles.tag}>
                  <p>Logo op Site</p>
                </div>
                <div className={styles.tag}>
                  <p>Vermelding socials</p>
                </div>
                <div className={styles.tag}>
                  <p>Blogposts</p>
                </div>
                <div className={`${styles.tag} ${styles.andMore}`}>
                  <p>En nog veel meer!</p>
                </div>
                <div className={`${styles.tag} ${styles.more}`}>
                  <p>Partner Landingspagina</p>
                </div>
                <div className={`${styles.tag} ${styles.more}`}>
                  <p>Partner Vermelding PR</p>
                </div>
                <div className={`${styles.tag} ${styles.more}`}>
                  <p>Press Release</p>
                </div>
                <div className={`${styles.tag} ${styles.more}`}>
                  <p>Vermelding Street PR</p>
                </div>
              </div>
            </div>
            <div className={styles.cardBottom}>
              <div className={styles.sponsorStar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 83 88"
                  fill="none"
                >
                  <path
                    d="M41.3631 0.608398L42.9682 38.9534L66.8054 8.87509L45.5653 40.8403L82.5295 30.5176L46.5573 43.8934L82.5295 57.2692L45.5653 46.9464L66.8054 78.9116L42.9682 48.8333L41.3631 87.1783L39.758 48.8333L15.9208 78.9116L37.1609 46.9464L0.196644 57.2692L36.1689 43.8934L0.196644 30.5176L37.1609 40.8403L15.9208 8.87509L39.758 38.9534L41.3631 0.608398Z"
                    fill="#213305"
                  />
                </svg>
              </div>
              <div className={styles.sponsorButton}>
                <p>Aanmelden</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <circle cx="12.4603" cy="12.6673" r="12.183" fill="#213305" />
                  <path
                    d="M17.9892 7.92937C17.9892 7.58601 17.7109 7.30766 17.3675 7.30766L11.7721 7.30766C11.4288 7.30766 11.1504 7.58601 11.1504 7.92937C11.1504 8.27273 11.4288 8.55108 11.7721 8.55108H16.7458V13.5247C16.7458 13.8681 17.0241 14.1465 17.3675 14.1465C17.7109 14.1465 17.9892 13.8681 17.9892 13.5247L17.9892 7.92937ZM8.16227 18.0138L17.8071 8.36899L16.9279 7.48976L7.28304 17.1346L8.16227 18.0138Z"
                    fill="#F0F9ED"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
